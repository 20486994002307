export default {
  methods: {
    getPhotoUser(url) {
      if (url) return url
      return 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
    getPhotoNutritionist(url) {
      if (url) return url
      return 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/profile_neutral.webp?updatedAt=1693289781599'
    },
    getPhotoNutritionistAlt(url) {
      if (url) return url
      return 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/profile_neutral_ahligizi.webp?updatedAt=1693289781641'
    },
    getPhotoProgram(url) {
      if (url) return url
      return require('@/assets/img-program.png')
    },
    getPhotoBlankGray(url) {
      if (url) return url
      return require('@/assets/images/image-gray.png')
    },
    formatBytes(bytes, decimals) {
      if (bytes == 0) return '0 Bytes'
      var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)))
      return Math.round((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    getYoutubeId(url) {
      if (!url) return ''
      let watch = url.split('/watch?v=')
      let slash = url.split('/')
      if (url.includes('watch') && watch.length > 0) {
        return watch[watch.length - 1]
      } else {
        return slash[slash.length - 1]
      }
    },
    trimText(value, length = 18) {
      if (value.length > length) {
        return `${value.substring(0, length)}...`
      }
      return value
    },
    isArray(item) {
      return Array.isArray(item)
    },
    capitalizeWords(string) {
      if (!string) return ''
      // Split the input string into words
      const words = string.replace('_', ' ').split(' ')

      // Capitalize the first letter of each word
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
      }

      // Join the words back together with a space
      const resultString = words.join(' ')

      return resultString
    },
  },
}
