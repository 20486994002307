<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/diet-program"
        >
          Manajemen Diet Program
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Detail Diet Program
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        {{ item.name }}
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'superadmin.diet-program.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>
    <c-text
      mb="30px"
      color="gray.900"
    >
      Dibuat: {{ item.createdAt }}
    </c-text>

    <c-box>
      <c-grid
        w="100%"
        template-columns="repeat(2, 1fr)"
        gap="6"
        mb="77px"
      >
        <c-box>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Overview Program
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
            >
              {{ item.overview }}
            </c-text>
          </c-form-control>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Deskripsi Program
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
            >
              {{ item.description }}
            </c-text>
          </c-form-control>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Created by
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
              text-transform="capitalize"
            >
              {{
                item.createBy
                  ? item.createBy.firstName + item.createBy.lastName
                  : "-"
              }}
            </c-text>
          </c-form-control>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Program ini cocok untuk
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
            >
              {{ item.suitableFor }}
            </c-text>
          </c-form-control>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Target Program
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
            >
              {{ item.programTarget }}
            </c-text>
          </c-form-control>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Fasilitas Program
            </c-text>
            <c-text
              color="superDarkGray.900"
              font-size="18px"
              line-height="27px"
            >
              {{ item.serviceFacility }}
            </c-text>
          </c-form-control>
        </c-box>
        <c-box>
          <c-form-control mb="30px">
            <c-text
              mb="2px"
              color="primary.400"
              font-weight="500"
              font-size="16px"
              line-height="24px"
            >
              Gambar Program
            </c-text>
            <c-box
              w="340px"
              min-w="340px"
              h="404px"
              bg="primary.400"
              overflow="hidden"
              border-radius="6px"
            >
              <c-image
                :src="getPhotoProgram(item.photoUrl)"
                :alt="item.name"
                w="100%"
              />
            </c-box>
          </c-form-control>
        </c-box>
      </c-grid>

      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
        color="primary.400"
        mb="25px"
      >
        Layanan Program
      </c-heading>
      <c-box
        v-for="(item, index) in item.productServices"
        :key="'layanan' + index"
        border="1px solid #008C81"
        box-sizing="border-box"
        border-radius="12px"
        py="25px"
        px="30px"
        mb="25px"
      >
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Nama Layanan
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            text-transform="capitalize"
          >
            {{ item.programsService }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Durasi Layanan
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            text-transform="capitalize"
          >
            {{ item.duration }} hari
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Harga
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            text-transform="capitalize"
          >
            Rp{{ Number(item.price).toLocaleString("id") }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Target Layanan
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
          >
            {{ item.serviceTarget }}
          </c-text>
        </c-form-control>
      </c-box>
    </c-box>

    <DeleteConfirm
      z-index="1000"
      :content="deletedText"
      :is-open="isOpen"
      @on-delete="onDelete"
    />
  </c-box>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DeleteConfirm from './../delete-confirm.vue'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'SuperadminDietProgramDetail',
  components: { DeleteConfirm },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.suManagementProgram.item,
    }),
    id() {
      return this.$route.params.id
    },
    deletedText() {
      return `Anda yakin ingin hapus Diet Program <br /> “${this.item.name}”?`
    },
  },
  async mounted() {
    this.detailProgram(this.id)
  },
  methods: {
    ...mapActions({
      detailProgram: 'suManagementProgram/detailProgramSuperadmin',
      deleteProgram: 'suManagementProgram/deleteProgramSuperadmin',
    }),
    closeDialog() {
      this.isOpen = false
    },
    onDelete(state) {
      this.isOpen = state
      if (!this.isOpen) return
      this.deleteProgram(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Diet Program has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.diet-program',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
  },
}
</script>
