var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-alert-dialog', {
    attrs: {
      "is-open": _vm.isOpen,
      "least-destructive-ref": _vm.$refs.cancelRef
    }
  }, [_c('c-alert-dialog-overlay', {
    attrs: {
      "z-index": "1"
    }
  }), _c('c-alert-dialog-content', {
    attrs: {
      "text-align": "center",
      "z-index": "2",
      "w": "488px",
      "top": "40px",
      "py": "40px",
      "px": "40px",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.1)",
      "border-radius": "12px"
    }
  }, [_c('c-image', {
    attrs: {
      "w": "176px",
      "mx": "auto",
      "mb": "49px",
      "src": require('@/assets/icon-confirm-delete.svg')
    }
  }), _c('c-alert-dialog-body', {
    attrs: {
      "text-align": "center",
      "mb": "35px",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }), _c('c-alert-dialog-footer', {
    attrs: {
      "p": "0"
    }
  }, [_c('c-button', {
    ref: "cancelRef",
    attrs: {
      "w": "50%",
      "h": "auto",
      "py": "17px",
      "border-radius": "60px"
    },
    on: {
      "click": function click($event) {
        return _vm.onSubmit(false);
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "w": "50%",
      "h": "auto",
      "py": "17px",
      "border-radius": "60px",
      "variant-color": "red",
      "ml": "3",
      "min-w": "80px"
    },
    on: {
      "click": function click($event) {
        return _vm.onSubmit(true);
      }
    }
  }, [_vm._v(" Hapus ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }